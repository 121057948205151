const ExperiencePipefy = () => {
  return (
    <div className="block-content pipefy">
      <h3>Pipefy</h3>
      <h4><span>Staff Product Designer</span> <br />jun de 2022 - ago de 2023</h4>
      <p>Experiência incrível na tribo de PLG explorando um rotina de testes e foco em conversão, adoção e retenção.</p>
    </div>
  );
}

export default ExperiencePipefy;
