const ExperienceGrupoRBS = () => {
  return (
    <div className="block-content grupo-rbs">
      <h3>Grupo RBS</h3>
      <h4><span>Lead Designer</span> <br /> ago de 2002 - dez de 2008</h4>
      <p>Gosto de dizer que a RBS foi o lugar no qual pude colocar a teoria em prática e realmente evoluir como profissional. A empresa possui um amplo portfólio de produtos multimídia, que vão desde canais de TV até jornais, publicações impressas e estações de rádio. Foi uma ótima oportunidade para aplicar novas metodologias de design e testar seu sucesso e impacto sobre uma audiência de milhões de usuários.</p>
      <h4>Destaques</h4>
      <ul>
        <li>Gestão do time de design da área de produtos digitais</li>
        <li>ClicEsportes - Portal de notícias esportivas</li>
      </ul>
    </div>
  );
}

export default ExperienceGrupoRBS;
