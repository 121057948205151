import ExperienceRDStation from '../blocks/experience-rdstation.js';
import ExperiencePipefy from '../blocks/experience-pipefy.js';
import ExperienceGVT from '../blocks/experience-gvt.js';
import ExperienceNET from '../blocks/experience-net.js';
import ExperienceGrupoRBS from '../blocks/experience-grupo-rbs.js';

const BlockExperience = () => {
  return (
    <div className="about">
      <h2>Experiência</h2>

      <ExperienceRDStation />
      <ExperiencePipefy />
      <ExperienceGVT />
      <ExperienceNET />
      <ExperienceGrupoRBS />

    </div>
  );
}

export default BlockExperience;
