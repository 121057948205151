const ExperienceNET = () => {
  return (
    <div className="block-content net">
      <h3>Claro <span>(NET)</span></h3>
      <h4><span>Interaction Designer</span> <br /> jan de 2009 - mai de 2011</h4>
      <p>Foi meu primeiro trabalho como designer dentro do departamento de marketing de uma grande empresa. Na NET (Claro), pude aprender e me envolver com os conceitos de marketing e foi uma grande oportunidade para entregar coisas incríveis. Também pude experimentar ser uma importante conexão entre os departamentos de marketing e engenharia.</p>
      <h4>Destaques de entrega</h4>
      <ul>
        <li>NET NOW, primeiro serviço vídeos sob demanda (VOD) da operadora</li>
        <li>Diversos apps de upsell pela TV</li>
        <li>NET música (streaming de música pela TV)</li>
      </ul>
    </div>
  );
}

export default ExperienceNET;
