import './App.css';
import SectionHeader from './section-header.js';
import BlockExperience from './blocks/experience.js';
import BlockAbout from './blocks/about.js';
import BlockContents from './blocks/contents.js';

function App() {
  return (
    <div className="App">

      <SectionHeader />

      <div className="section">

        <div className="column color-3">

          <BlockExperience />

        </div>

        <div className="column color-2">

          <BlockAbout />

        </div>

        <div className="column color-1">

          <BlockContents />

        </div>

      </div>

    </div>
  );
}

export default App;
