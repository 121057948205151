import AboutBio from '../blocks/about-bio.js';

const BlockAbout = () => {
  return (
    <div className="about">
      <h2>Sobre</h2>

      <AboutBio />

    </div>
  );
}

export default BlockAbout;
