const BlockContent = () => {
  return (
    <div className="contents">
      <h2>contéudos</h2>
      <p>Um pouco de contéudo meu ou sobre mim espalhado por ae...</p>
      <h4>redes</h4>
      <ul>
      <li><a href="https://www.linkedin.com/in/rquaresma/" target="_blank" rel="noopener">Linkedin</a></li>
      <li><a href="https://github.com/rodrigoquaresma/" target="_blank" rel="noopener">GitHub</a></li>
      <li><a href="https://www.flickr.com/photos/rodrigoquaresma/" target="_blank" rel="noopener">Flickr</a></li>
      <li><a href="https://airbnb.com.br/p/rosabutia" target="_blank" rel="noopener">Airbnb (superhost)</a></li>
      </ul>
      <h4>artigos</h4>
      <ul>
        <li><a href="https://brasil.uxdesign.cc/por-que-ux-por-rodrigo-quaresma-dd289d41c2d6" target="_blank" rel="noopener">“Por que UX?”, por Rodrigo Quaresma</a></li>
        <li><a href="https://brasil.uxdesign.cc/um-resum%C3%A3o-do-ebai-2011-9a32b1fdfdd5#:~:text=Interatividade%20+%20TV" target="_blank" rel="noopener">Um resumão do Ebai 2011</a></li>
        <li><a href="https://faberhaus.com.br/interatividade-e-tv/" target="_blank" rel="noopener">Faberhaus: Interatividade e TV: O quanto de interação queremos quando assistimos algo?</a></li>
      </ul>
      <h4>talks</h4>
      <ul>
      <li><a href="https://medium.com/design-rd/escalando-times-de-ux-podcast-com-rodrigo-quaresma-da-resultados-digitais-a1fda84c627e" target="_blank" rel="noopener">Escalando times de UX: Podcast com Rodrigo Quaresma da Resultados Digitais</a></li>
        <li><a href="https://www.infoq.com/br/presentations/a-jornada-de-um-ux-designer-dentro-de-uma-startup/" target="_blank" rel="noopener">UX Conf 2016: A jornada de um UX designer dentro de uma startup</a></li>
        <li><a href="https://www.slideshare.net/rquaresma/tv-interatividade-9887494" target="_blank" rel="noopener">5º EBAI - Encontro Brasileiro de Arquitetura de Informação: TV + interatividade</a></li>
        <li><a href="https://www.slideshare.net/rquaresma/a-ux-do-dia-a-dia" target="_blank" rel="noopener">4ª Rodada Digital - Estávcio de Sá: A UX do dia a dia</a></li>
      </ul>

    </div>
  );
}

export default BlockContent;
