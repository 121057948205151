const ExperienceRDStation = () => {
  return (
    <div className="block-content rdstation">
      <h3>RD Station</h3>
      <h4><span>Lead Product Designer</span> <br />jan de 2013 - ago de 2021</h4>
      <p>Cheguei na RD como um dos 5 primeiros funcionários e primeiro designer. Acompanhei toda jornada de um start-up recém criada: Ideação, Operação, Tração, Scale-up e Exit de R$ 2 bi. Durante os quase 9 anos dessa jornada, pude participar da concepção das funcionalidades core do produto de automação de marketing, montagem e gestão do primeiro time de design de produto e estruturação da empresa.</p>
      <h4>Notícias sobre a empresa</h4>
      <ul>
        <li><a href="https://braziljournal.com/totvs-leva-rd-station-por-r-2-bi-valuation-de-ipo/" target="_blank" rel="noopener"><strong>Braziljournal:</strong> Totvs leva RD Station por R$ 2 bi, “valuation de IPO”</a></li>
        <li><a href="https://exame.com/tecnologia/por-que-a-totvs-gastou-quase-r-2-bilhoes-na-compra-da-rd-station/" target="_blank" rel="noopener"><strong>Exame:</strong> Por que a Totvs gastou quase R$ 2 bilhões na compra da RD Station</a></li>
        <li><a href="https://blog.eqseed.com/startup-exit-rd-station/" target="_blank" rel="noopener"><strong>EqSeed:</strong> Startup Exit: a história da RD Station até o valuation de R$2 bi</a></li>
      </ul>
      <h4>Entregas em destaque</h4>
      <ul>
        <li>Contratação e gestão do primeiro time de designers do produto.</li>
        <li>Concepção e execução da primeira versão do produto.</li>
        <li>Última versão do criador de Landing Pages e Emails.</li>
      </ul>
    </div>
  );
}

export default ExperienceRDStation;
