import logo from './profile.jpeg';


const SectionHeader = () => {
  return (
      <header className="header">
        <div className="header-profile">
          <img src={logo} className="profile-thumb" alt="rodrigo quaresma" />
          <h1>Rodrigo <span>Quaresma</span></h1>
        </div>
        <ul>
          <li>product <span>designer</span></li>
          <li>builder</li>
          <li>host</li>
          <li>surfer</li>
        </ul>
      </header>
  );
}

export default SectionHeader;
