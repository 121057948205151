const ExperienceGVT = () => {
  return (
    <div className="block-content gvt">
      <h3>Vivo <span>(GVT TV)</span></h3>
      <h4><span>Senior Product Manager</span> <br />mai de 2011 - jan de 2013</h4>
      <p>Apesar de ser uma grande empresa, quando comecei na GVT, senti-me dentro de uma startup. Iniciamos uma unidade de negócios dentro da GVT, promovendo um ambiente para atrair pessoas talentosas de outras empresas para se juntarem ao nosso projeto. O objetivo era construir um novo negócio de TV paga. Eu era responsável por desenvolver novos aplicativos para este novo produto, trabalhando tanto como designer quanto como gerente de produto.</p>
      <h4>Destaques</h4>
      <ul>
        <li>Interface do EPG (Electronic Programming Guide)</li>
        <li>App de Instagram para TV</li>
        <li>App de Facebook para TV</li>
        <li>App das Olimpíadas 2012 para TV</li>
      </ul>
    </div>
  );
}

export default ExperienceGVT;
