const AboutBio = () => {
  return (
    <div className="block-content about-bio">
      <p>Sou natural de Porto Alegre, capital do Rio Grande do Sul, estado no extremo sul do Brasil.</p>
      <p>Cresci com muito apreço pelo desenho e por contar estórias, especialmente quadrinhos, animações, filmes e livros.</p>
      <p>E foi buscando algo nessa linha que acabei me encontrando no design gráfico. Mas não demorou muito para que a internet entrasse e eu acabasse mergulhando de cabeça na experiência do usuário dos produtos digitais como designer de interação.</p>
      <p>E isso ainda era sobre contar estórias e criar experiências incríveis...</p>

      <h4>minha primeira mudança</h4>
      <p>Após uma grande experiência na área de produtos digitais do Grupo RBS, maior grupo de mídia do sul do país, recebi a oportunidade de mudar para São Paulo/SP e continuar meu trabalho com interfaces, mas dessa vez em outra tela, a TV.</p>

      <h4>segunda mudança</h4>
      <p>Depois de 4 anos na capital paulista, sentimos muito a falta do mar. Nosso sonho, meu e da minha esposa, sempre foi morar no litoral e sentimos que era hora de empreender essa mudança. Foi quando pedi ajuda para alguns amigos me indicarem para empresas de tecnologia sediadas na ilha de Florianópolis, capital de Santa Catarina. O que eu não esperava era que esses amigos acabariam me oferecendo uma oportunidade na start-up que eles estavam fundando. Essa empresa era a Resultados Digitais (mais tarde conhecida como RD Station). Encaramos essa jornada que durou quase 9 anos e foi maravilhosa. Cheguei em uma empresa que cabia inteira dentro de uma sala com menos de 15 m2 e saí de lá após um <a href="https://braziljournal.com/totvs-leva-rd-station-por-r-2-bi-valuation-de-ipo/" target="_blank" rel="noopener">valuation de R$ 2 bilhões</a>.</p>

      <h4>terceira mudança</h4>

      <p>Enquanto ainda estava na RD Station e a pandemia era só coisa de filme, minha família e eu resolvemos mudar novamente. Desta vez fomos para uma pequena cidade a 70 km de Florianópolis: Imbituba. Mais conhecida como Praia do Rosa. Morar nesse lugar era nosso sonho de adolescente, pois foi nessa praia que tirávamos férias quando ainda éramos bastante jovens.</p>
      <p>Além do lugar ser um paraíso (já foi eleita <a href="​​https://g1.globo.com/sc/santa-catarina/verao/2014/noticia/2013/12/praia-do-rosa-em-sc-esta-na-lista-das-30-baias-mais-belas-do-mundo.html" target="_blank" rel="noopener">uma das 30 baías mais lindas do mundo</a>), ainda tínhamos nossa memória afetiva aqui.</p>
      <p>Desde então, já construímos nossa casa e compramos alguns <a href="http://www.rosabutia.com" target="_blank" rel="noopener">chalés</a> nos quais oferecemos <a href="http://www.airbnb.com.br/p/rosabutia" target="_blank" rel="noopener">hospedagens</a> especializadas para nômades digitais. Nossa missão nesse espaço é que todos tenham um pouco da experiência de viver o equilíbrio entre "trabalho e férias" todos os dias do ano.</p>

      <h4>alguns do meus interesses</h4>
      <p>Surfar.</p>
      <p>Contruir coisas. Principalmente com marcenaria.</p>
      <p>Ler de tudo. Mas principalmente ficção (Asimov é um dos meus favoritos), arte e design</p>
      <p>Aprender programação. Principalmente front-end (<a href="https://github.com/rodrigoquaresma/rodrigoquaresma_app" target="_blank" rel="noopener">fiz esta página</a> para aprender React).</p>

    </div>
  );
}

export default AboutBio;
